import React, { PropsWithChildren } from 'react'
import Grid from '@/components/Grid'
import { GridVerticalAlignType } from '@/enums/Grid'
import {
  ContentButtonItem,
  ContentIframeItem,
  ContentRow,
  ContentTextItem,
  ContentVideoItem,
} from '@/types/Content'
import styled, { css } from 'styled-components'
import TransitionHandler from '@/components/TransitionHandler'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import {
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { EffectType } from '@/enums/Content'
import Iframe from '@/components/Iframe'
import Video from '@/components/Video'
import AutoLinkText from '../AutoLinkText'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'

interface Grid2VideoContentsWithTextProps {
  item: ContentRow
  inverted?: boolean
}

const Partial = {
  Video({ video }: { video: ContentVideoItem[] }) {
    if (!video) return null
    return (
      <>
        {video?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid2ReverseVideoWithText-video-${index}`}
          >
            <Styled.Video
              src={item?.src}
              imageSrc={item?.imageSrc}
              imageAlt={item?.imageAlt || ''}
            />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Iframe({ iframe }: { iframe: ContentIframeItem[] }) {
    if (!iframe) return null
    return (
      <>
        {iframe?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid2ReverseVideoWithText-iframe-${index}`}
          >
            <Styled.Iframe
              width={item?.width}
              height={item?.height}
              src={item?.src}
              allowFullScreen={item?.allowFullScreen}
              allow={item?.allow}
              title={item?.title}
            />
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  VideoTitle({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ReverseVideoWithText-videoTitle-${index}`}
          >
            <Styled.VideoTitle as={as}>{value}</Styled.VideoTitle>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  VideoText({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ReverseVideoWithText-videoText-${index}`}
          >
            <Styled.VideoText>{value}</Styled.VideoText>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid2ReverseVideoWithText-button-${index}`}
          >
            <Styled.Button {...item}>{item?.label}</Styled.Button>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ReverseVideoWithText-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2ReverseVideoWithText-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid2VideoContentsWithText({
  item,
  inverted = false,
}: PropsWithChildren<Grid2VideoContentsWithTextProps>) {
  const { columns, type } = item
  const [{ items: leftColumnItems }, { items: RightColumnItems }] = columns
  const {
    iframe,
    video,
    title: videoTitle,
    text: videoText,
    button,
  } = elementItemHelper(leftColumnItems)
  const { title, text } = elementItemHelper(RightColumnItems)

  return (
    <>
      <Grid
        gridType={type}
        inverted={inverted}
        verticalAlign={GridVerticalAlignType.TOP}
      >
        <TransitionHandler>
          <Styled.TextWrap>
            <Partial.Title title={title} />
            <Partial.Text text={text} />
          </Styled.TextWrap>
        </TransitionHandler>

        <Styled.VideoWrap>
          <TransitionHandler effect={item?.effectType ?? EffectType.WIPE_RIGHT}>
            <Partial.Iframe iframe={iframe} />
            <Partial.Video video={video} />
            <Styled.VideoTextWrap>
              <Partial.VideoTitle title={videoTitle} />
              <Partial.VideoText text={videoText} />
            </Styled.VideoTextWrap>
            <Styled.ButtonWrap>
              <Partial.Button button={button} />
            </Styled.ButtonWrap>
          </TransitionHandler>
        </Styled.VideoWrap>
      </Grid>
    </>
  )
}

const Styled = {
  VideoWrap: styled.div`
    max-width: 852px;
    margin: auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 50px;
      max-width: 100%;
      max-height: none;
      height: auto;
    }
  `,
  Video: styled(Video)`
    width: 100%;
  `,
  Iframe: styled(Iframe)`
    ${({ width }) => {
      if (typeof width !== 'number') {
        return css`
          width: 100%;
        `
      }
    }};

    height: calc(100vw - 75vw);
    max-height: 475px; // 디자인 시안은 500px. 원본영상 비율과 안맞아서 협의하에 변경.

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      height: calc(100vw - 49vw);
      max-height: none;
    }
  `,
  VideoTextWrap: styled.div`
    margin-bottom: 24px;
    white-space: pre-line;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 20px;
    }
  `,
  VideoTitle: styled.div`
    ${FONT_22_EXTRA_BOLD};
    margin-bottom: 20px;
    margin-top: 50px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
      margin-bottom: 12px;
      margin-top: 24px;
    }
  `,
  VideoText: styled.p`
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
  `,
  TextWrap: styled.div`
    width: 66.7%;
    margin: 0 auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: auto;
    }
  `,
  Title: styled.div`
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  ButtonWrap: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  `,
}

export default Grid2VideoContentsWithText
