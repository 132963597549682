import React, { PropsWithChildren, useState } from 'react'
import Grid from '@/components/Grid'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import styled from 'styled-components'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { FONT_16, FONT_22_EXTRA_BOLD, FONT_30_EXTRA_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import Tabs from '@/components/Tabs'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import TransitionHandler from '@/components/TransitionHandler'
import { EffectType } from '@/enums/Content'
import AutoLinkText from '@/components/AutoLinkText'

interface Grid2ImageWithTabProps {
  item: ContentRow
}

const Partial = {
  Image({ tabImage }: { tabImage: ContentImageItem }) {
    if (!tabImage) return null

    return (
      <TransitionHandler effect={tabImage.effectType ?? EffectType.WIPE_RIGHT}>
        <DisplayConfigWrapper show={tabImage.show}>
          <Styled.Image src={tabImage.src} alt={tabImage.alt || ''} />
        </DisplayConfigWrapper>
      </TransitionHandler>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <TransitionHandler key={`Grid2ImageWithTab-title-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as}>{value}</Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <TransitionHandler key={`Grid2ImageWithTab-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  RightTabImage({ tabImage }: { tabImage: ContentImageItem }) {
    if (!tabImage) return
    return (
      <TransitionHandler>
        <DisplayConfigWrapper show={tabImage.show}>
          <Styled.TabImage src={tabImage.src} alt={tabImage.alt || ''} />
        </DisplayConfigWrapper>
      </TransitionHandler>
    )
  },
  TabText({ text }: { text: ContentTextItem }) {
    if (!text) return
    return (
      <TransitionHandler>
        <Styled.TabText>{text.value}</Styled.TabText>
      </TransitionHandler>
    )
  },
  TabButton({ button }: { button: ContentButtonItem }) {
    if (!button) return
    return (
      <TransitionHandler>
        <Styled.Button {...button}>{button?.label}</Styled.Button>
      </TransitionHandler>
    )
  },
}

function Grid2ImageWithTab({
  item,
}: PropsWithChildren<Grid2ImageWithTabProps>) {
  const [tabId, setTabId] = useState<number>(0)
  const { columns, type } = item

  const handleClickTab = (index: number): void => {
    return setTabId(index)
  }

  const [{ items: leftColumnItems }, { items: rightColumnItems }] = columns ?? [
    {},
    {},
  ]

  const { tabImage: leftTabImage } = elementItemHelper(leftColumnItems ?? [])

  const {
    title,
    text,
    tab,
    tabButton,
    tabText,
    tabImage: rightTabImage,
  } = elementItemHelper(rightColumnItems ?? [])

  return (
    <Styled.Grid gridType={type}>
      <Styled.Wrap>
        <Partial.Image tabImage={leftTabImage?.[tabId]} />
      </Styled.Wrap>
      <Styled.Wrap>
        <Styled.RightColumn>
          <Styled.TextWrap>
            <Partial.Title title={title} />
            <Partial.Text text={text} />
          </Styled.TextWrap>
          <TransitionHandler>
            <Styled.Tabs
              list={tab}
              onClick={handleClickTab}
              hiddenTitle={item?.tabHiddenTitle}
              line
              padding
            >
              <Partial.RightTabImage tabImage={rightTabImage?.[tabId]} />
              <Styled.TabContent>
                <Partial.TabText text={tabText[tabId]} />
                <Partial.TabButton button={tabButton[tabId]} />
              </Styled.TabContent>
            </Styled.Tabs>
          </TransitionHandler>
        </Styled.RightColumn>
      </Styled.Wrap>
    </Styled.Grid>
  )
}

const Styled = {
  Grid: styled(Grid)`
    padding: 0 5%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0;
    }
  `,
  Wrap: styled.div`
    & + & {
      padding-top: 80px;
    }
  `,
  Image: styled.img`
    width: 100%;
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      padding: 0 6px;
    }
  `,
  RightColumn: styled.div`
    padding-left: 150px;
    margin: 0 6px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 24px 0;
      margin: 0;
    }
  `,
  TextWrap: styled.div`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0 5%;
    }
  `,
  Title: styled.div`
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK01};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  Tabs: styled(Tabs)`
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 20px;
    }
  `,
  TabContent: styled.div`
    white-space: pre-wrap;
    padding-top: 16px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 16px 5% 0;
    }
  `,
  TabImage: styled.img`
    width: 100%;
    height: 100%;
  `,
  TabText: styled.p`
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
  `,
  Button: styled(AutoLinkText)`
    display: block;
    ${STYLE_ARROW_BUTTON_BLACK};
    padding-top: 30px;
    text-align: left;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
      text-align: center;
    }
  `,
}

export default Grid2ImageWithTab
