import React from 'react'
import styled from 'styled-components'
import elementItemHelper from '@/helpers/elementItemHelper'
import Grid from '@/components/Grid'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { ContentRow } from '@/types/Content'
import {
  STYLE_APPSTORE_BUTTON,
  STYLE_GOOGLEPLAY_BUTTON,
  STYLE_STORE_BUTTON_DEFAULT,
} from '@/styles/buttons'
import { STYLE_LINE_HEIGHT } from '@/styles/fonts'
import TransitionHandler from '@/components/TransitionHandler'
import AutoLinkText from '@/components/AutoLinkText'
import { STYLE_BREAKPOINT } from '@/styles/variables'

interface Grid1ButtonsProps {
  item: ContentRow
}

function Grid1StoreButtons({ item }: Grid1ButtonsProps) {
  const { columns, type } = item
  const [{ items }] = columns ?? [{}]
  const { button } = elementItemHelper(items)
  const [googlePlayButton, appStoreButton, mobileButton] = button
  return (
    <Grid gridType={type}>
      <Styled.Wrap>
        <TransitionHandler>
          <Styled.DisplayConfigWrapper show={googlePlayButton?.show}>
            <Styled.GooglePlayButton
              href={googlePlayButton?.href}
              {...googlePlayButton}
            >
              {googlePlayButton?.label}
            </Styled.GooglePlayButton>
          </Styled.DisplayConfigWrapper>
          <Styled.DisplayConfigWrapper show={appStoreButton?.show}>
            <Styled.AppStoreButton
              href={appStoreButton?.href}
              {...appStoreButton}
            >
              {appStoreButton?.label}
            </Styled.AppStoreButton>
          </Styled.DisplayConfigWrapper>
        </TransitionHandler>
        <TransitionHandler>
          <Styled.DisplayConfigWrapper show={mobileButton?.show}>
            <Styled.OutlineButtonLarge
              href={mobileButton?.href}
              target={mobileButton?.target}
            >
              {mobileButton?.label}
            </Styled.OutlineButtonLarge>
          </Styled.DisplayConfigWrapper>
        </TransitionHandler>
      </Styled.Wrap>
    </Grid>
  )
}

const Styled = {
  DisplayConfigWrapper: styled(DisplayConfigWrapper)`
    display: inline-block;
  `,
  Wrap: styled.div`
    text-align: center;
  `,
  GooglePlayButton: styled(AutoLinkText)`
    ${STYLE_GOOGLEPLAY_BUTTON};
    margin-right: 10px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      width: 200px;
      height: 56px;
      padding: 12px 0;
      margin: 0 auto;
      line-height: ${STYLE_LINE_HEIGHT._30};
    }
  `,
  AppStoreButton: styled(AutoLinkText)`
    ${STYLE_APPSTORE_BUTTON};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      width: 200px;
      height: 56px;
      padding: 12px 0;
      margin: 10px auto 0;
      line-height: ${STYLE_LINE_HEIGHT._30};
    }
  `,
  OutlineButtonLarge: styled(AutoLinkText)`
    ${STYLE_STORE_BUTTON_DEFAULT};
    width: 200px;
    height: 56px;
    padding: 14px 0;
    line-height: ${STYLE_LINE_HEIGHT._30};
  `,
}

export default Grid1StoreButtons
