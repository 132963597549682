import elementItemHelper from '@/helpers/elementItemHelper'
import {
  FONT_16,
  FONT_16_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentHeadingItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface Grid1AgreementProps {
  item: ContentRow
}

const Partial = {
  Heading({ heading }: { heading?: ContentHeadingItem[] }) {
    if (!heading) return null
    return (
      <>
        {heading?.map(({ level, value }, i) => (
          <React.Fragment key={i}>
            {level === 1 && <Styled.H1>{value}</Styled.H1>}
            {level === 2 && <Styled.H2>{value}</Styled.H2>}
            {level === 3 && <Styled.H3>{value}</Styled.H3>}
          </React.Fragment>
        ))}
      </>
    )
  },
  Text({ text }: { text?: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value }, i) => (
          <Styled.Text key={i}>{value}</Styled.Text>
        ))}
      </>
    )
  },
}

function Grid1Agreement({ item }: PropsWithChildren<Grid1AgreementProps>) {
  const { columns } = item
  const [{ items }] = columns ?? [{}]
  const { heading, text } = elementItemHelper(items)

  if (!columns) return null
  return (
    <Styled.Wrap>
      <Partial.Heading heading={heading} />
      <Partial.Text text={text} />
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    max-width: ${STYLE_BREAKPOINT.PC_MAX_WIDTH};
    margin: auto;
    padding: 0 5%;
  `,
  H1: styled.h1`
    ${FONT_30_EXTRA_BOLD};
  `,
  H2: styled.h2`
    ${FONT_22_EXTRA_BOLD};
    margin-bottom: 20px;
  `,
  H3: styled.h3`
    ${FONT_16_BOLD};
  `,
  Text: styled.p`
    ${FONT_16};
    margin-bottom: 10px;
    color: ${STYLE_COLOR.BLACK02};
    white-space: pre-wrap;
  `,
}

export default Grid1Agreement
