import React, { PropsWithChildren, ReactNode } from 'react'
import {
  ContentBackgroundItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import styled, { css } from 'styled-components'
import {
  FONT_100_BOLD,
  FONT_18_EXTRA_BOLD,
  FONT_22,
  FONT_48_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ContentShowType, EffectType, ImageFitType } from '@/enums/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import TransitionHandler from '@/components/TransitionHandler'

interface Grid1StaticIntroProps {
  item: ContentRow
}

interface GridStyleWrapProps {
  pc?: string
  mobile?: string
  pcFit?: ImageFitType
  mobileFit?: ImageFitType
}

const Partial = {
  Background({
    items,
    children,
  }: {
    items: ContentBackgroundItem[]
    children: ReactNode
  }) {
    const pc = items.find((item) => item.show === ContentShowType.PC)
    const mobile = items.find((item) => item.show === ContentShowType.MOBILE)
    return (
      <Styled.Wrap
        pc={pc.src}
        mobile={mobile.src}
        pcFit={pc.fit}
        mobileFit={mobile.fit}
      >
        {children}
      </Styled.Wrap>
    )
  },
  Text({ items }: { items: ContentTextItem[] }) {
    const [item] = items
    return <Styled.Text>{item?.value}</Styled.Text>
  },
  Title({ items }: { items: ContentTextItem[] }) {
    return (
      <>
        {items.map((item, index) => (
          <DisplayConfigWrapper
            key={`Grid1StaticIntro-title-${index}`}
            show={item.show}
          >
            <Styled.Title as={item.as}>{item?.value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid1StaticIntro({ item }: PropsWithChildren<Grid1StaticIntroProps>) {
  const [contentItems] = item?.columns ?? []
  const { backgroundImage, subTitle, title } = elementItemHelper(
    contentItems.items
  )
  return (
    <TransitionHandler effect={EffectType.WIPE_RIGHT}>
      <Partial.Background items={backgroundImage}>
        <Styled.Fixer>
          <Partial.Text items={subTitle} />
          <Partial.Title items={title} />
        </Styled.Fixer>
      </Partial.Background>
    </TransitionHandler>
  )
}

const Styled = {
  Wrap: styled.div<Partial<GridStyleWrapProps>>`
    position: relative;
    text-align: center;
    min-height: 980px;
    max-height: 100vh;
    min-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH};
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    background-position: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-position: center;
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-height: 0;
      height: 100vh;
      min-width: 0;
      margin-top: -56px; /* header height */
    }

    ${({ mobile, mobileFit, pc, pcFit }) =>
      css`
        background-image: url(${pc});
        background-size: ${pcFit === ImageFitType.CONTAIN
          ? 'contain'
          : 'cover'};

        &::before {
          background-image: url(${mobile});
          background-size: ${mobileFit === ImageFitType.CONTAIN
            ? 'contain'
            : 'cover'};
        }

        @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
          &::before {
            opacity: 1;
          }
        }
      `}
    &::after {
      content: '';
      display: inline-block;
      overflow: hidden;
      width: 0;
      min-height: 980px;
      height: 100%;
      max-height: 100vh;
      vertical-align: middle;
    }
  `,
  Fixer: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: pre-line;
  `,
  Title: styled.div`
    ${FONT_100_BOLD};
    color: ${STYLE_COLOR.YELLOW};
    word-break: keep-all;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100vw;
      ${FONT_48_BOLD};
    }
  `,
  Text: styled.p`
    ${FONT_22};
    color: ${STYLE_COLOR.WHITE01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
}

export default Grid1StaticIntro
