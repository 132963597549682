import React from 'react'
import elementItemHelper from '@/helpers/elementItemHelper'
import Grid from '@/components/Grid'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import styled from 'styled-components'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { FONT_26_EXTRA_BOLD, FONT_40_EXTRA_BOLD } from '@/styles/fonts'
import { ContentRow, ContentTextItem } from '@/types/Content'

interface Grid1CenterAlignedTitleProps {
  item: ContentRow
}

const Partial = {
  Title({ title }: { title: ContentTextItem[] }) {
    return (
      <>
        {title.map((item, index) => (
          <DisplayConfigWrapper
            key={`Grid1CenterAlignedTitle-title-${index}`}
            show={item.show}
          >
            <Styled.Title as={item.as}>{item.value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid1CenterAlignedTitle({ item }: Grid1CenterAlignedTitleProps) {
  const { columns, type } = item
  const [{ items }] = columns ?? [{}]
  const { title } = elementItemHelper(items)

  return (
    <Grid gridType={type}>
      <Partial.Title title={title} />
    </Grid>
  )
}

const Styled = {
  Title: styled.div`
    text-align: center;
    ${FONT_40_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_26_EXTRA_BOLD};
    }
  `,
}

export default Grid1CenterAlignedTitle
