import { ContentShowType } from '@/enums/Content'
import { FONT_14, FONT_16_BOLD } from '@/styles/fonts'
import { STYLE_COLOR, STYLE_BREAKPOINT } from '@/styles/variables'
import { ComponentStyledOptionalProps } from '@/types/Component'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface DescriptionsProps extends ComponentStyledOptionalProps {
  item: Array<{
    title: string
    text: string
    show?: ContentShowType
  }>
}

function Descriptions({
  className,
  item,
}: PropsWithChildren<DescriptionsProps>) {
  return (
    <Styled.Descriptions className={className}>
      <tbody>
        {item?.map(({ title, text }, index) => (
          <tr key={`Descriptions-${index}`}>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Description dangerouslySetInnerHTML={{ __html: text }} />
          </tr>
        ))}
      </tbody>
    </Styled.Descriptions>
  )
}

const Styled = {
  Descriptions: styled.table`
    margin-bottom: 30px;
    tr:first-child td {
      padding-top: 0;
    }
  `,
  Title: styled.th`
    ${FONT_16_BOLD};
    padding-right: 20px;
    white-space: nowrap;
    text-align: left;
  `,
  Description: styled.td`
    ${FONT_14};
    padding: 8px 15px 8px 0;
    word-break: break-all;
    white-space: pre-line;

    a {
      color: ${STYLE_COLOR.PRIMARY01};
      text-decoration: none;

      &.anchor_block {
        display: block;
        margin-top: 4px;
      }
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-right: 0;
      white-space: normal;
    }
  `,
}

export default Descriptions
