import React from 'react'
import { ContentButtonItem, ContentRow } from '@/types/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import Grid from '@/components/Grid'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import styled from 'styled-components'
import { STYLE_OUTLINE_BUTTON } from '@/styles/buttons'
import { ContentShowType } from '@/enums/Content'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import AutoLinkText from '@/components/AutoLinkText'

interface Grid2RightButtonsProps {
  item: ContentRow
}

const Partial = {
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button.map((item, index) => (
          <Styled.displayConfigWrapper
            show={item?.show}
            key={`Grid2RightButtonSet-button-${index}`}
          >
            <Styled.OutlineButton {...item}>{item?.label}</Styled.OutlineButton>
          </Styled.displayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid2RightButtons({ item }: Grid2RightButtonsProps) {
  const { columns, type } = item
  const [{ items }] = columns ?? []

  const { button } = elementItemHelper(items)

  const pcButton = button.filter((item) => item.show === ContentShowType.PC)
  const mobileButton = button.filter(
    (item) => item.show === ContentShowType.MOBILE
  )

  return (
    <Styled.Wrap>
      <Grid gridType={type}>
        <div />
        <Partial.Button button={pcButton} />
      </Grid>
      <Partial.Button button={mobileButton} />
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div``,
  displayConfigWrapper: styled(DisplayConfigWrapper)`
    & + & {
      margin-top: 10px;
    }
  `,
  OutlineButton: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON};
    display: block;
    width: 330px;
    padding: 9px 0 9px 20px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin: 0 auto;
      padding: 9px 0;
      text-align: center;
    }
  `,
}

export default Grid2RightButtons
