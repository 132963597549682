import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import TransitionHandler from '@/components/TransitionHandler'
import { GridVerticalAlignType } from '@/enums/Grid'
import elementItemHelper from '@/helpers/elementItemHelper'
import {
  FONT_12,
  FONT_14,
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import React from 'react'
import styled from 'styled-components'
import { ContentListItem, ContentTextItem } from '@/types/Content'

const Partial = {
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`GridTitleWithContents-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  SubTitle({ subTitle }: { subTitle: ContentTextItem[] }) {
    if (!subTitle) return null
    return (
      <>
        {subTitle?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`GridTitleWithContents-sub-title-${index}`}
          >
            <Styled.SubTitle>{value}</Styled.SubTitle>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  List({ list }: { list: ContentListItem[] }) {
    if (!list) return null
    const [mainList, descList] = list

    return (
      <>
        {mainList && (
          <Styled.MainList>
            {mainList.list.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </Styled.MainList>
        )}
        {descList && (
          <Styled.DescList>
            {descList.list.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </Styled.DescList>
        )}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`GridTitleWithContents-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

// Grid2TitleWithContents와 유사하지만 확장이 어려워 별도로 추가한 컴포넌트 입니다.
// TODO: 추후 Grid2TitleWithContents와 컴포넌트와 통합
function Grid2TitleWithContents2({ item, inverted = false }) {
  const { columns, type } = item
  const [{ items: titleColumnItems }, { items: ContentColumnItems }] = columns

  const { title } = elementItemHelper(titleColumnItems)
  const { subTitle, list, text } = elementItemHelper(ContentColumnItems)

  return (
    <>
      <Styled.Grid
        gridType={type}
        inverted={inverted}
        verticalAlign={GridVerticalAlignType.TOP}
      >
        <TransitionHandler>
          <Styled.Wrap>
            <Styled.TitleWrap>
              <Partial.Title title={title} />
            </Styled.TitleWrap>
            <Styled.ContentWrap>
              <Partial.SubTitle subTitle={subTitle} />
              <Partial.Text text={text} />
              <Partial.List list={list} />
            </Styled.ContentWrap>
          </Styled.Wrap>
        </TransitionHandler>
      </Styled.Grid>
    </>
  )
}

const Styled = {
  Grid: styled(Grid)`
    box-sizing: border-box;
    padding: 0 5%;
    white-space: pre-line;
    word-break: keep-all;

    & + & {
      margin-top: 100px;
      padding-top: 100px;
      border-top: 1px solid ${STYLE_COLOR.BLACK05};

      @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
        margin-top: 60px;
        padding-top: 60px;
      }
    }
  `,
  Wrap: styled.div`
    display: flex;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
    }
  `,
  TitleWrap: styled.div`
    flex: 0 0 288px;
    padding-left: 16.667%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex: none;
      padding-left: 0;
    }
  `,
  ContentWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  Title: styled.div`
    ${FONT_22_EXTRA_BOLD};
    display: block;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 20px;
      text-align: center;
    }
  `,
  SubTitle: styled.div`
    ${FONT_22_EXTRA_BOLD};
    display: block;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
      text-align: center;
    }
  `,
  MainList: styled.ol`
    margin: 0;
    padding-left: 16px;
    ${FONT_16};
    list-style-type: decimal;
  `,
  DescList: styled.ul`
    margin: 0;
    padding-left: 16px;
    ${FONT_14};
    color: ${STYLE_COLOR.BLACK02};
    list-style-type: disc;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_12};
    }
  `,
  Text: styled.p`
    ${FONT_16};
  `,
}

export default Grid2TitleWithContents2
