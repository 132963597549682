import React, { PropsWithChildren, useState } from 'react'
import styled, { css } from 'styled-components'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { FONT_22_EXTRA_BOLD } from '@/styles/fonts'
import { ContentTabItem } from '@/types/Content'
import { STYLE_INIT_BUTTON } from '@/styles/buttons'
import { trackEvent } from '@/helpers/logger'

interface TabProps {
  list: ContentTabItem[]
  onClick: (index: number) => void
  className?: string
}

interface TabOptionalStyleProps {
  isCurrent: boolean
}

type TabStyleProps = Partial<TabOptionalStyleProps>

function LinedTabs({ list, className, onClick }: PropsWithChildren<TabProps>) {
  const [currentTabId, setCurrentTabId] = useState<number>(0)

  const handleClick = (index): void => {
    setCurrentTabId(index)
    const currentItem = list.find((item) => item.order === index)
    const { logPageName, logEventName } = currentItem
    trackEvent({ pageName: logPageName, actionName: logEventName })
    return onClick(index)
  }

  const renderTabItem = (item: ContentTabItem, index: number) => {
    return (
      <Styled.Button
        key={`LinedTabItem${index}`}
        onClick={() => handleClick(item.id)}
        isCurrent={currentTabId === item.id}
      >
        {item.label}
      </Styled.Button>
    )
  }

  const sortedList = list.sort((a, b) => a.id - b.id)

  return (
    <Styled.TabWrap className={className}>
      <Styled.Tab>{sortedList.map(renderTabItem)}</Styled.Tab>
    </Styled.TabWrap>
  )
}

const Styled = {
  TabWrap: styled.div`
    width: 100%;
  `,
  Tab: styled.nav`
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      border-top: 1px solid ${STYLE_COLOR.WHITE04_30};
      border-bottom: 1px solid ${STYLE_COLOR.WHITE04_30};
    }
  `,
  Button: styled.button<TabStyleProps>`
    ${STYLE_INIT_BUTTON};
    width: 100%;
    text-align: left;
    padding: 12px 0;
    transition: all 0.5s ease-in-out;
    ${FONT_22_EXTRA_BOLD};
    color: ${STYLE_COLOR.WHITE02_60};
    &:hover {
      color: ${STYLE_COLOR.YELLOW};
    }
    & + & {
      border-top: 1px solid ${STYLE_COLOR.WHITE04_30};
    }
    ${({ isCurrent }) => {
      return (
        isCurrent &&
        css`
          color: ${STYLE_COLOR.YELLOW};
        `
      )
    }};
  `,
}

export default LinedTabs
