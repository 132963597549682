import React, { PropsWithChildren } from 'react'
import { ContentBackgroundItem, ContentRow } from '@/types/Content'
import styled, { css } from 'styled-components'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'

interface Grid1PatternBackgroundProps {
  item: ContentRow
}

function Grid1PatternBackground({
  item,
}: PropsWithChildren<Grid1PatternBackgroundProps>) {
  const [column] = item?.columns ?? []
  if (!item || !column) {
    return null
  }
  const { backgroundImage } = elementItemHelper(column?.items)
  if (!backgroundImage) {
    return null
  }
  return (
    <>
      {backgroundImage.map((item, index) => (
        <DisplayConfigWrapper
          show={item.show}
          key={`Grid1PatternBackground-${index}`}
        >
          <Styled.Pattern {...item} />
        </DisplayConfigWrapper>
      ))}
    </>
  )
}

const Styled = {
  Pattern: styled.div<Partial<ContentBackgroundItem>>`
    background-position: center;
    ${({ src, width, height }) => css`
      background-image: url(${src});
      background-size: ${width && height && `${width}px ${height}px`};
      height: ${height && `${height}px`};
    `}
  `,
}

export default Grid1PatternBackground
