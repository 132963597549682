import React from 'react'
import { ContentHorizontalRow } from '@/types/Content'
import styled from 'styled-components'
import Grid from '@/components/Grid'
import { STYLE_COLOR } from '@/styles/variables'

interface Grid0HorizontalProps {
  item: ContentHorizontalRow
}

function Grid0Horizontal({ item }: Grid0HorizontalProps) {
  return (
    <Grid spacing>
      <Styled.Line {...item} />
    </Grid>
  )
}

const Styled = {
  Line: styled.span<ContentHorizontalRow>`
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    border-top: 1px solid ${STYLE_COLOR.BLACK02};
  `,
}

export default Grid0Horizontal
