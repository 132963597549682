import { css } from 'styled-components'

export const STYLE_PC_SHOW_MASK_TRANSITION = css`
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
`

export const STYLE_PC_MASK_HOLDER_DEFAULT_TRANSITION = css`
  transition: all 0.5s 0.2s ease-out;
`

export const STYLE_PC_SHOWTITLE_TRANSITION = css`
  transition: all 0.8s 0.5s ease-out;
  transform: translate3d(0, 0, 0);
  opacity: 1;
`
