import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import Icon, { IconProps, IconTypeFile } from '@/components/Icon'
import { ContentItemType, ImageFitType } from '@/enums/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import { trackEvent } from '@/helpers/logger'

import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import {
  FONT_30_EXTRA_BOLD,
  FONT_40_EXTRA_BOLD,
  STYLE_FONT_SIZE,
  STYLE_FONT_WEIGHT,
  STYLE_KEEP_WORD,
  STYLE_LINE_HEIGHT,
} from '@/styles/fonts'
import {
  BackgroundPathType,
  STYLE_CLEAR_FIX,
  STYLE_SAFE_BACKGROUND_IMAGE,
  STYLE_SYSTEM_GRID_INSIDE,
} from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentBackgroundItem,
  ContentButtonItem,
  ContentColumn,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import React, {
  PropsWithChildren,
  useCallback,
  useId,
  useMemo,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import AutoLinkText from '../AutoLinkText'
import { Hidden } from '@kakaomobility/tui-react'

interface Grid2AccordionProps {
  key?: string
  item: ContentRow
}

interface Grid2AccordionStyleProps {
  fold: boolean
}

const ButtonGroup = styled.div`
  text-align: left;
  &:first-child,
  > a:first-child {
    margin-top: 0;
  }
  @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
    margin-top: 52px;
  }
`

const Partial = {
  DefaultPart({
    icons,
    titles,
    subTitles,
    onClick,
    fold,
    accordionIds,
  }: {
    icons: ContentBackgroundItem[]
    titles: ContentTextItem[]
    subTitles: ContentTextItem[]
    onClick: () => void
    fold: boolean
    accordionIds: {
      header: string
      content: string
    }
  }) {
    const [icon] = icons
    return (
      <Styled.DefaultContainer>
        <Styled.Icon {...icon} />
        {subTitles?.map((subTitle, index) => (
          <DisplayConfigWrapper
            show={subTitle.show}
            key={`Grid2AccordionDefaultPart-subTitle-${index}`}
          >
            <Styled.DefaultSubTitle as={subTitle?.as}>
              {subTitle.value}
            </Styled.DefaultSubTitle>
          </DisplayConfigWrapper>
        ))}
        {titles?.map((title, index) => (
          <DisplayConfigWrapper
            show={title.show}
            key={`Grid2AccordionDefaultPart-title-${index}`}
          >
            <Styled.DefaultTitle as={title?.as}>
              {title.value}
            </Styled.DefaultTitle>
          </DisplayConfigWrapper>
        ))}
        <Styled.DefaultTrigger
          onClick={onClick}
          fold={fold}
          id={accordionIds.header}
          aria-expanded={!fold}
          aria-controls={accordionIds.content}
        >
          <Styled.TriggerIcon type={IconTypeFile.IC_48_FOLD} fold={fold} />
          <Hidden>상세보기</Hidden>
        </Styled.DefaultTrigger>
      </Styled.DefaultContainer>
    )
  },
  FoldablePart({
    column,
    fold,
    onClick,
    accordionIds,
  }: {
    column: ContentColumn
    fold: boolean
    onClick: () => void
    accordionIds: {
      header: string
      content: string
    }
  }) {
    if (!column?.items) {
      return null
    }

    const links = column.items.filter(
      (item) =>
        item.type === ContentItemType.BUTTON && item.buttonStyle !== 'button'
    ) as ContentButtonItem[]

    const buttons = column.items.filter(
      (item) =>
        item.type === ContentItemType.BUTTON && item.buttonStyle === 'button'
    ) as ContentButtonItem[]

    return (
      <>
        <Styled.FoldableContainer
          fold={fold}
          id={accordionIds.content}
          aria-labelledby={accordionIds.header}
          aria-hidden={fold}
        >
          {column.items.map((item, index) => {
            const key = `Grid2Accordion-foldablePart-${index}`
            switch (item.type) {
              case ContentItemType.IMAGE:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.Image {...item} alt={item.alt || ''} />
                  </DisplayConfigWrapper>
                )
              case ContentItemType.TITLE:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.Title>{item.value}</Styled.Title>
                  </DisplayConfigWrapper>
                )
              case ContentItemType.SUB_TITLE:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.SubTitle>{item.value}</Styled.SubTitle>
                  </DisplayConfigWrapper>
                )
              case ContentItemType.TEXT:
                return (
                  <DisplayConfigWrapper show={item.show} key={key}>
                    <Styled.Text>{item.value}</Styled.Text>
                  </DisplayConfigWrapper>
                )
            }
          })}

          {links.length > 0 && (
            <Styled.LinkGroup>
              {links.map((link, index) => (
                <DisplayConfigWrapper
                  show={link?.show}
                  key={`Grid2Accordion-foldablePart-Link-${index}`}
                >
                  <Styled.Link
                    href={link?.href}
                    target={link?.target}
                    tabIndex={fold ? -1 : 0}
                  >
                    {link?.label}
                  </Styled.Link>
                </DisplayConfigWrapper>
              ))}
            </Styled.LinkGroup>
          )}
          {buttons.length > 0 && (
            <ButtonGroup>
              {buttons.map((button, index) => (
                <Styled.Button
                  key={`Grid2Accordion-foldablePart-Button-${index}`}
                  tabIndex={fold ? -1 : 0}
                  href={button.href}
                  target={button.target}
                >
                  {button.label}
                  {button.subLabel && (
                    <Styled.SubLabel>{button.subLabel}</Styled.SubLabel>
                  )}
                </Styled.Button>
              ))}
            </ButtonGroup>
          )}

          <Styled.FoldableTrigger onClick={onClick} aria-expanded={!fold}>
            <Styled.TriggerIcon type={IconTypeFile.IC_48_FOLD} fold={fold} />
          </Styled.FoldableTrigger>
        </Styled.FoldableContainer>
      </>
    )
  },
}

function Grid2Accordion({ item }: PropsWithChildren<Grid2AccordionProps>) {
  const [defaultColumn, foldableColumn] = item.columns

  const { backgroundImage, title, subTitle, button } = elementItemHelper(
    defaultColumn.items
  )

  const uniqueId = useId()

  const accordionIds = useMemo(
    () => ({
      header: `accordion-header-${uniqueId}`,
      content: `accordion-content-${uniqueId}`,
    }),
    [uniqueId]
  )

  // 첫번째 Toggle 컨텐츠 = 최초 열림으로 설정
  const [fold, setFold] = useState(item.id !== 0)

  const handleClick = useCallback(() => {
    setFold((prevState) => !prevState)

    if (!button) {
      return
    }

    if (fold) {
      const [item] = button
      const { logPageName, logEventName } = item
      trackEvent({ pageName: logPageName, actionName: logEventName })
    }
  }, [button, fold])

  if (!item?.columns) {
    return null
  }

  return (
    <Styled.Wrap>
      <Styled.Fixer>
        <>
          <Partial.DefaultPart
            icons={backgroundImage}
            titles={title}
            subTitles={subTitle}
            onClick={handleClick}
            accordionIds={accordionIds}
            fold={fold}
          />
          <Partial.FoldablePart
            column={foldableColumn}
            onClick={handleClick}
            fold={fold}
            accordionIds={accordionIds}
          />
        </>
      </Styled.Fixer>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    min-width: ${STYLE_BREAKPOINT.PC_MIN_WIDTH};
    margin: 0 auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      min-width: 0;
    }
  `,
  Fixer: styled(Grid)`
    ${STYLE_CLEAR_FIX};
    position: relative;
  `,
  DefaultContainer: styled.div`
    position: relative;
    padding: 130px 16.6667% 130px 33.3333%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      border-top: 1px solid ${STYLE_COLOR.BLACK02};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      position: static;
      padding-top: 50px;
      padding-bottom: 60px;
      text-align: center;
    }
  `,
  FoldableContainer: styled.div<Grid2AccordionStyleProps>`
    position: relative;
    overflow: hidden;
    transition: opacity 0.25s ease-in-out;
    padding-left: 33.3333%;
    padding-right: 16.6667%;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0;
      text-align: center;
    }
    ${({ fold }) => {
      if (fold) {
        return css`
          padding-bottom: 0;
          height: 0;
          opacity: 0;
        `
      }
      return css`
        padding-bottom: 200px;
        height: auto;
        opacity: 1;
        @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
          padding-bottom: 75px;
        }
      `
    }};
  `,
  Icon: styled.i<Partial<ContentBackgroundItem>>`
    position: absolute;
    top: 50%;
    left: 144px;
    display: block;
    width: 140px;
    height: 140px;
    margin: -70px 0 0;
    background-repeat: no-repeat;
    ${({ src, fit }) => css`
      background-image: url(${src});
      background-size: ${fit === ImageFitType.COVER ? 'cover' : 'contain'};
    `};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      position: static;
      margin: 0 auto;
      width: 90px;
      height: 90px;
    }
  `,
  DefaultTitle: styled.div`
    ${FONT_40_EXTRA_BOLD};
    line-height: 60px;
    ${STYLE_KEEP_WORD};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 8px;
      font-size: ${STYLE_FONT_SIZE._26};
      line-height: ${STYLE_LINE_HEIGHT._36};
    }
  `,
  DefaultSubTitle: styled.div`
    ${FONT_30_EXTRA_BOLD};
    ${STYLE_KEEP_WORD};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 24px;
      font-size: ${STYLE_FONT_SIZE._22};
      line-height: ${STYLE_LINE_HEIGHT._34};
    }
  `,
  DefaultTrigger: styled.button<Grid2AccordionStyleProps>`
    position: absolute;
    top: 50%;
    right: 114px;
    display: block;
    width: 48px;
    height: 48px;
    margin: -24px 0 0;
    padding: 0;
    background: none transparent;
    border: 0 none;
    cursor: pointer;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin: 24px auto 0;
      ${({ fold }) => {
        if (fold) {
          return css`
            position: static;
          `
        }
        return css`
          position: absolute;
          opacity: 0;
        `
      }};
    }
  `,
  FoldableTrigger: styled.button`
    display: none;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0;
    background: none transparent;
    border: 0 none;
    cursor: pointer;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      margin-top: 30px;
    }
  `,
  TriggerIcon: styled(Icon)<IconProps & Grid2AccordionStyleProps>`
    transition: transform 0.25s ease-out;
    transform: ${({ fold }) => fold && 'rotate(180deg)'};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      transform: scale(0.5);
      transform-origin: left;

      ${({ fold }) =>
        fold &&
        css`
          transform: scale(0.5) rotate(180deg);
          transform-origin: initial;
        `};
    }
  `,
  Image: styled.img<Partial<ContentImageItem>>`
    display: block;
    max-width: 100%;

    & ~ & {
      margin-top: 80px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      & ~ & {
        margin-top: 60px;
      }
    }
  `,
  Title: styled.div`
    ${STYLE_KEEP_WORD};
    margin-top: 50px;
    font-size: ${STYLE_FONT_SIZE._30};
    font-weight: ${STYLE_FONT_WEIGHT.EXTRA_BOLD};
    line-height: ${STYLE_LINE_HEIGHT._46};
    color: ${STYLE_COLOR.BLACK01};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      margin-top: 24px;
      font-size: ${STYLE_FONT_SIZE._22};
      line-height: ${STYLE_LINE_HEIGHT._34};
      text-align: left;
    }
  `,
  SubTitle: styled.div`
    ${STYLE_KEEP_WORD};
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: ${STYLE_FONT_SIZE._22};
    line-height: ${STYLE_LINE_HEIGHT._34};
    color: ${STYLE_COLOR.BLACK02};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      font-size: ${STYLE_FONT_SIZE._18};
      line-height: ${STYLE_LINE_HEIGHT._28};
      text-align: left;
    }
  `,
  Text: styled.div`
    ${STYLE_KEEP_WORD};
    margin-top: 24px;
    font-size: ${STYLE_FONT_SIZE._16};
    line-height: ${STYLE_LINE_HEIGHT._30};
    color: ${STYLE_COLOR.BLACK02};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${STYLE_SYSTEM_GRID_INSIDE};
      margin-top: 12px;
      text-align: left;
    }
  `,
  SubLabel: styled.span`
    margin-left: auto;
  `,
  LinkGroup: styled.div`
    margin-bottom: 24px;
    text-align: left;

    & + ${ButtonGroup} {
      margin-top: 40px;
    }
  `,
  Button: styled(AutoLinkText)`
    position: relative;
    box-sizing: border-box;
    display: flex;
    max-width: 680px;
    align-items: center;
    margin-top: 24px;
    padding: 8px 53px 8px 20px;
    font-size: ${STYLE_FONT_SIZE._16};
    border: 1px solid ${STYLE_COLOR.BLACK03};
    text-decoration: none;
    line-height: ${STYLE_LINE_HEIGHT._30};
    text-align: left;
    color: ${STYLE_COLOR.BLACK01};

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: calc(50% - 8px);

      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_16_DOWNLOAD,
        16,
        16,
        BackgroundPathType.ICONS
      )};
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 10px;
      text-align: center;
      &:after {
        display: none;
      }
    }
  `,
  Link: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
  `,
}

export default Grid2Accordion
