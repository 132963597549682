import { IconTypeFile } from '@/components/Icon'
import {
  ContentItemType,
  ContentLogEventActionName,
  ContentLogPageName,
} from '@/enums/Content'
import { trackEvent } from '@/helpers/logger'
import { STYLE_INIT_BUTTON } from '@/styles/buttons'
import {
  FONT_14,
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import {
  BackgroundPathType,
  STYLE_MULTI_BACKGROUND,
  STYLE_SAFE_BACKGROUND_IMAGE,
} from '@/styles/presets'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentHistoryItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
interface Grid1HistoryProps {
  item: ContentRow
}

interface Grid1HistoryElementMap {
  year: string
  month: ContentHistoryItem[]
}

interface Grid1HistoryMonthValueProps {
  value: string
  history: ContentHistoryItem[]
}

interface HistoryStyleProps {
  fold: boolean
}

function Grid1History({ item }: Grid1HistoryProps) {
  const [column] = item?.columns ?? []
  const { items } = column ?? {}

  // 연혁 Title
  const title = items.find(
    (history: ContentTextItem) => history.type === ContentItemType.TITLE
  ) as ContentTextItem

  // 연혁 Contents
  const historyItems = items
    .filter((history) => history.type === ContentItemType.HISTORY)
    .sort(
      (c: ContentHistoryItem, d: ContentHistoryItem) =>
        Number(d.month) - Number(c.month)
    )
    .sort(
      (a: ContentHistoryItem, b: ContentHistoryItem) =>
        Number(b.year) - Number(a.year)
    )

  const historyMap = historyItems.reduce(
    (
      groupedHistory,
      history: ContentHistoryItem
    ): Grid1HistoryElementMap[] | [] => {
      const { year, month: monthValue } = history
      const yearItem = groupedHistory.find(
        (currentYear) => currentYear?.year === year
      )

      if (yearItem) {
        const monthItem = yearItem.month.find(
          (month: ContentHistoryItem) => month.value === monthValue
        )

        monthItem
          ? monthItem.history.push(history)
          : yearItem.month.push({ value: monthValue, history: [history] })
      } else {
        groupedHistory.push({
          year,
          month: [{ value: monthValue, history: [history] }],
        })
      }

      return groupedHistory
    },
    []
  )

  const renderHistoryElements = useCallback(
    (yearItem) => {
      if (historyMap.length < 1) {
        return null
      }

      const { month: monthValues } = historyMap.find(
        (currentYear) => currentYear.year === yearItem.year
      )

      return (
        <YearContents.List key={`Grid1History-yearList-${yearItem.year}`}>
          <YearContents.Title>{yearItem.year}</YearContents.Title>
          <YearContents.Content>
            {monthValues.map((month: Grid1HistoryMonthValueProps) => {
              return (
                <Styled.MonthList
                  key={`Grid1History-monthList-${yearItem.year}-${month.value}`}
                >
                  <Styled.MonthTitle>{month.value}월</Styled.MonthTitle>
                  <Styled.MonthValue>
                    {month.history.map((history: ContentHistoryItem, index) => {
                      return (
                        <Styled.MonthValueDesc
                          key={`Grid1History-monthValue-${yearItem}-${history.value}-${index}`}
                        >
                          {history.value}
                        </Styled.MonthValueDesc>
                      )
                    })}
                  </Styled.MonthValue>
                </Styled.MonthList>
              )
            })}
          </YearContents.Content>
        </YearContents.List>
      )
    },
    [historyMap]
  )

  // 더보기 fold state
  const [fold, setFold] = useState(true)

  const onClickMore = () => {
    setFold(!fold)
    trackEvent({
      pageName: ContentLogPageName.KAKAOMOBILITY_STORY,
      actionName: ContentLogEventActionName.KAKAOMOBILITY_STORY_HISTORY_MORE,
    })
  }

  return (
    <>
      <Styled.HistoryTitle as={title?.as}>{title.value}</Styled.HistoryTitle>
      <Styled.History fold={fold}>
        {historyMap.map(renderHistoryElements)}
        <Styled.BtnMore fold={fold} onClick={onClickMore}>
          더보기
        </Styled.BtnMore>
      </Styled.History>
    </>
  )
}

const Styled = {
  HistoryTitle: styled.strong`
    display: block;
    margin-bottom: 122px;
    ${FONT_30_EXTRA_BOLD};
    text-align: center;
    white-space: pre-line;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 0 6.666%;
      margin-bottom: 40px;
      ${FONT_22_EXTRA_BOLD};
      text-align: left;
    }
  `,
  History: styled.div<HistoryStyleProps>`
    display: table;
    margin: auto;

    ${({ fold }) =>
      fold &&
      css`
        ${YearContents.List} {
          &:nth-child(n + 3) {
            display: none;
          }
        }
      `}

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding: 0 6.666%;
    }
  `,
  MonthList: styled.dl`
    display: flex;
    cursor: default;
    ${FONT_16};

    &:last-child dd:last-child {
      margin-bottom: 0;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_14};
    }
  `,
  MonthTitle: styled.dt`
    flex-shrink: 0;
    flex-grow: 0;
    width: 32px;
    padding-right: 32px;
    color: ${STYLE_COLOR.BLACK03};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-right: 12px;
    }
  `,
  MonthValue: styled.dd``,
  MonthValueDesc: styled.p`
    ${STYLE_KEEP_WORD};
    flex-grow: 1;
    margin-bottom: 20px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 32px;
      }
    }
  `,
  BtnMore: styled.button<HistoryStyleProps>`
    ${STYLE_INIT_BUTTON};
    ${FONT_16};
    display: block;
    margin: 80px auto 0;
    text-align: center;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
      padding: 10px 0;
      margin: 30px auto -10px;
    }

    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      transition: margin-left 0.2s ease-out;

      ${STYLE_SAFE_BACKGROUND_IMAGE(
        IconTypeFile.IC_16_FOLD,
        16,
        16,
        BackgroundPathType.ICONS
      )};
    }

    ${({ fold }) =>
      !fold &&
      css`
        &:after {
          ${STYLE_MULTI_BACKGROUND(
            IconTypeFile.IC_16_UNFOLD,
            BackgroundPathType.ICONS
          )}
        }
      `}
  `,
}

// Styled. 에서 내부 참조 하기위해 별도 분리
const YearContents = {
  List: styled.dl`
    display: flex;
    margin-bottom: 40px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
    }
  `,
  Title: styled.dt`
    width: 52px;
    padding-right: 40px;
    white-space: nowrap;
    ${FONT_22};
    line-height: 30px;
    color: ${STYLE_COLOR.BLACK01};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      width: auto;
      margin-bottom: 16px;
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Content: styled.dd`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
    }
  `,
}

export default Grid1History
