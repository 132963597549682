import elementItemHelper from '@/helpers/elementItemHelper'
import { STYLE_OUTLINE_BUTTON_LINK } from '@/styles/buttons'
import { FONT_14, FONT_16_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ComponentStyledOptionalProps } from '@/types/Component'
import {
  ContentButtonItem,
  ContentDescriptionItem,
  ContentListItem,
  ContentTableValue,
  ContentTextItem,
} from '@/types/Content'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import AutoLinkText from './AutoLinkText'
import Descriptions from './Descriptions'
import DisplayConfigWrapper from './DisplayConfigWrapper'
import List from './List'

interface TableProps extends ComponentStyledOptionalProps {
  article: Array<{
    title: string
    value: ContentTableValue[]
  }>
}

const Partial = {
  List({ item }: { item: ContentListItem[] }) {
    if (!item) return null
    return (
      <>
        {item.map(({ list }, index) => (
          <Styled.List list={list} key={index} />
        ))}
      </>
    )
  },

  Descriptions({ item }: { item: ContentDescriptionItem[] }) {
    if (!item) return null
    return (
      <>
        {item.map(({ item }, index) => (
          <Descriptions item={item} key={index} />
        ))}
      </>
    )
  },

  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text.map(({ value, show }, index) => (
          <DisplayConfigWrapper show={show} key={`Table-text-${index}`}>
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },

  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button.map(({ label, href, show }, index) => {
          return (
            <DisplayConfigWrapper key={`Table-button-${index}`} show={show}>
              <Styled.OutlineButton href={href}>{label}</Styled.OutlineButton>
            </DisplayConfigWrapper>
          )
        })}
      </>
    )
  },
}

function Table({ className, article }: PropsWithChildren<TableProps>) {
  return (
    <Styled.Wrap className={className}>
      {article?.map((article, index, arr) => {
        const { list, descriptions, text, button } = elementItemHelper(
          article.value
        )
        return (
          <Styled.WrapContent key={`Table-${index}`} length={arr.length}>
            <Styled.Title>{article.title}</Styled.Title>
            <Styled.Contents>
              <Partial.List item={list} />
              <Partial.Descriptions item={descriptions} />
              <Partial.Text text={text} />
              <Partial.Button button={button} />
            </Styled.Contents>
          </Styled.WrapContent>
        )
      })}
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.dl`
    display: flex;

    &:not(:first-child) {
      margin-top: 40px;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      padding-left: 0;
    }
  `,
  WrapContent: styled.div<{ length: number }>`
    display: flex;
    flex-direction: column;
    width: ${({ length }) => `${100 / length}%`};
    border-right: 1px solid ${STYLE_COLOR.BLACK04};

    &:last-child {
      border-right: 0;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: block;
      width: auto;
      border-right: 0;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  `,

  Title: styled.dt`
    padding: 13px 0;
    ${FONT_16_BOLD};
    border-top: 2px solid #000;
    border-bottom: 1px solid ${STYLE_COLOR.BLACK04};
    text-align: center;
    color: #000;
  `,

  Contents: styled.dd`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 30px 30px 40px;
    border-bottom: 1px solid ${STYLE_COLOR.BLACK04};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 20px 0;
    }
  `,

  Text: styled.p`
    ${FONT_14};
    margin-bottom: 10px;
  `,

  List: styled(List)`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-left: 20px;
    }
  `,

  OutlineButton: styled(AutoLinkText)`
    ${STYLE_OUTLINE_BUTTON_LINK};
    margin-top: 10px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: flex;
      width: 100%;
      margin-left: 0;

      &:first-of-type {
        margin-top: 20px;
      }
    }
  `,
}

export default Table
