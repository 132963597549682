import {
  FONT_12_EXTRA_BOLD,
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { Hidden } from '@kakaomobility/tui-react'
import { ForwardedRef, HTMLAttributes, forwardRef } from 'react'
import styled from 'styled-components'

interface NewsroomListItemProps extends HTMLAttributes<HTMLAnchorElement> {
  thumbnail: string
  tab: string
  title: string
  summary: string
  date: string
}

const NewsroomListItem = forwardRef(
  (
    { thumbnail, tab, title, summary, date, ...rest }: NewsroomListItemProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    return (
      <Styled.Wrap ref={ref} {...rest}>
        <Styled.Thumbnail src={thumbnail} alt='' />

        <Styled.ContentWrap>
          <Styled.CategoryWrap>
            <Styled.Category>{tab}</Styled.Category>
          </Styled.CategoryWrap>

          <Styled.TextWrap>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Summary>{summary}</Styled.Summary>
            <Styled.Date>
              <Hidden>작성 일자</Hidden>
              {date}
            </Styled.Date>
          </Styled.TextWrap>
        </Styled.ContentWrap>
      </Styled.Wrap>
    )
  }
)

NewsroomListItem.displayName = 'NewsroomListItem'

export default NewsroomListItem

const Styled = {
  Wrap: styled.a`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    overflow: hidden;
    height: 248px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      flex-direction: column;
      height: auto;
    }
  `,
  Thumbnail: styled.img`
    flex-shrink: 0;
    width: 420px;
    padding: 0 6px;
    object-fit: cover;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: 100%;
      height: calc((100vw * 0.9) * 2 / 3);
      padding: 0;
    }
  `,
  ContentWrap: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 80px;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-left: 0;
    }
  `,
  CategoryWrap: styled.div`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 16px;
    }
  `,
  Category: styled.span`
    padding: 4px 8px;
    ${FONT_12_EXTRA_BOLD};
    color: ${STYLE_COLOR.BLACK04};
    background-color: #f5f6f7;
    border-radius: 4px;
  `,
  TextWrap: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 8px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 8px;
      gap: 4px;
    }
  `,
  Title: styled.h4`
    ${FONT_22_EXTRA_BOLD};
    color: ${STYLE_COLOR.BLACK01};

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Summary: styled.p`
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    text-overflow: ellipsis;
  `,
  Date: styled.p`
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
  `,
}
