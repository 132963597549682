import { FONT_14 } from '@/styles/fonts'
import { STYLE_BREAKPOINT } from '@/styles/variables'
import { ComponentStyledOptionalProps } from '@/types/Component'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface ListProps extends ComponentStyledOptionalProps {
  list: string[]
  ordered?: boolean
}

function List({ className, list }: PropsWithChildren<ListProps>) {
  return (
    <Styled.List className={className}>
      {list?.map((value, index) => {
        return <li key={`List-${index}}`}>{value}</li>
      })}
    </Styled.List>
  )
}

const Styled = {
  List: styled.ul`
    padding-left: 30px;

    li {
      position: relative;
      padding-left: 20px;
      ${FONT_14};
      line-height: 30px;

      &:before {
        position: absolute;
        left: 7px;
        top: 1em;
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
      }
    }

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-left: 0;
    }
  `,
}

export default List
