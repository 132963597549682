import { PageComponents } from '@/enums/Component'
import InquiryTable from './privacy-policy/InquiryTable'

function ComponentRender({ name }: { name: PageComponents }) {
  switch (name) {
    case PageComponents.PRIVACY_POLICY__INQUIRY_TABLE:
      return <InquiryTable />
    default:
      return null
  }
}

export default ComponentRender
