import AutoLinkText from '@/components/AutoLinkText'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import Grid from '@/components/Grid'
import TransitionHandler from '@/components/TransitionHandler'
import Video from '@/components/Video'
import { EffectType } from '@/enums/Content'
import elementItemHelper from '@/helpers/elementItemHelper'
import { STYLE_ARROW_BUTTON_BLACK } from '@/styles/buttons'
import {
  FONT_16,
  FONT_18_EXTRA_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentButtonItem,
  ContentRow,
  ContentTextItem,
  ContentVideoItem,
} from '@/types/Content'
import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

interface VideoWithTextProps {
  item: ContentRow
  inverted?: boolean
  contentId?: string
}

interface Grid2VideoWithTextStyledProps {
  contentId: string
}

const Partial = {
  Video({
    video,
    contentId,
  }: {
    video: ContentVideoItem[]
    contentId: string
  }) {
    if (!video) return null
    return (
      <>
        {video?.map((item, index) => (
          <Styled.VideoWrap
            contentId={contentId}
            key={`Grid2VideoWithText-video-${index}`}
          >
            <TransitionHandler
              effect={item?.effectType ?? EffectType.WIPE_RIGHT}
              order={item?.effectOrder}
            >
              <DisplayConfigWrapper show={item?.show}>
                <Styled.Video
                  width={item?.width}
                  height={item?.height}
                  src={item?.src}
                  imageSrc={item?.imageSrc}
                  imageAlt={item?.imageAlt || ''}
                />
              </DisplayConfigWrapper>
            </TransitionHandler>
          </Styled.VideoWrap>
        ))}
      </>
    )
  },
  SubTitle({ subTitle }: { subTitle: ContentTextItem[] }) {
    if (!subTitle) return null
    return (
      <>
        {subTitle?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2VideoWithText-subTitle-${index}`}
          >
            <Styled.SubTitle>{value}</Styled.SubTitle>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) return null
    return (
      <>
        {title?.map(({ value, show, as }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2VideoWithText-title-${index}`}
          >
            <Styled.Title as={as}>{value}</Styled.Title>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text?.map(({ value, show }, index) => (
          <DisplayConfigWrapper
            show={show}
            key={`Grid2VideoWithText-text-${index}`}
          >
            <Styled.Text>{value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
  Button({ button }: { button: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button?.map((item, index) => (
          <DisplayConfigWrapper
            show={item?.show}
            key={`Grid2VideoWithText-button-${index}`}
          >
            <Styled.Button {...item}>{item?.label}</Styled.Button>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid2VideoWithText({
  item,
  inverted = false,
  contentId,
}: PropsWithChildren<VideoWithTextProps>) {
  const { columns, type } = item

  const [{ items: leftColumnItems }, { items: RightColumnItems }] = columns

  const { video } = elementItemHelper(leftColumnItems)
  const { title, text, subTitle, button } = elementItemHelper(RightColumnItems)

  return (
    <Grid gridType={type} inverted={inverted}>
      <Partial.Video video={video} contentId={contentId} />
      <TransitionHandler>
        <Styled.TextWrap>
          <Partial.SubTitle subTitle={subTitle} />
          <Partial.Title title={title} />
          <Partial.Text text={text} />
          <Partial.Button button={button} />
        </Styled.TextWrap>
      </TransitionHandler>
    </Grid>
  )
}

const Styled = {
  VideoWrap: styled.div<Grid2VideoWithTextStyledProps>`
    ${({ contentId }) => {
      if (contentId === 'main') {
        return css`
          @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
            /* 메인페이지 - 비디오 좌우여백 제거 */
            margin: 0 calc(-5vw + -6px);
          }
        `
      }
    }};
  `,
  Video: styled(Video)`
    ${({ width, height }) => {
      if (typeof width !== 'number' || typeof height !== 'number') {
        return css`
          width: 100%;
          height: 100%;
        `
      }
      return css`
        width: 100%;
        min-width: ${(width / height) * 100}vh;
        height: 100%;
        min-height: ${(height / width) * 100}vw;
      `
    }};
  `,
  TextWrap: styled.div`
    width: 66.7%;
    margin: 0 auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      width: auto;
      margin-top: 24px;
    }
  `,
  SubTitle: styled.div`
    ${FONT_22_EXTRA_BOLD};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD};
    }
  `,
  Title: styled.div`
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  Button: styled(AutoLinkText)`
    ${STYLE_ARROW_BUTTON_BLACK};
    padding-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
}

export default Grid2VideoWithText
