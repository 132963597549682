import React, { PropsWithChildren } from 'react'
import {
  ContentColumn,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import styled from 'styled-components'
import elementItemHelper from '@/helpers/elementItemHelper'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import { STYLE_CLEAR_FIX } from '@/styles/presets'
import {
  FONT_16,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
  STYLE_FONT_FAMILY,
  STYLE_KEEP_WORD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import Grid from '@/components/Grid'

interface Grid2GalleryProps {
  item: ContentRow
}

const Partial = {
  Part({ gallery }: { gallery: ContentColumn }) {
    if (!gallery) {
      return null
    }
    const { image, title, text } = elementItemHelper(gallery.items)
    return (
      <Styled.Part>
        <Partial.Image image={image} />
        <Partial.Title title={title} />
        <Partial.Text text={text} />
      </Styled.Part>
    )
  },
  Image({ image }: { image: ContentImageItem[] }) {
    if (!image) {
      return null
    }
    const [item] = image
    return (
      <DisplayConfigWrapper show={item.show}>
        <Styled.Image {...item} alt={item.alt || ''} />
      </DisplayConfigWrapper>
    )
  },
  Title({ title }: { title: ContentTextItem[] }) {
    if (!title) {
      return null
    }
    const [item] = title
    return (
      <DisplayConfigWrapper show={item.show}>
        <Styled.Title as={item.as}>{item.value}</Styled.Title>
      </DisplayConfigWrapper>
    )
  },
  Text({ text }: { text: ContentTextItem[] }) {
    if (!text) {
      return null
    }
    return (
      <>
        {text.map((item, index) => (
          <DisplayConfigWrapper
            show={item.show}
            key={`Grid2Gallery-text-${index}`}
          >
            <Styled.Text>{item.value}</Styled.Text>
          </DisplayConfigWrapper>
        ))}
      </>
    )
  },
}

function Grid2Gallery({ item }: PropsWithChildren<Grid2GalleryProps>) {
  const [firstGallery, secondGallery] = item?.columns ?? []

  if (!firstGallery && !secondGallery) {
    return null
  }

  return (
    <Styled.Wrap spacing>
      <Partial.Part gallery={firstGallery} />
      <Partial.Part gallery={secondGallery} />
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled(Grid)`
    margin: 0 auto;
    font-family: ${STYLE_FONT_FAMILY.KAKAO_BIG};
  `,
  Part: styled.div`
    ${STYLE_CLEAR_FIX};
    text-align: center;
    padding-top: 100px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 60px;
    }
  `,
  Image: styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      max-width: 220px;
    }
  `,
  Title: styled.strong`
    ${STYLE_KEEP_WORD};
    display: block;
    margin-top: 50px;
    ${FONT_30_EXTRA_BOLD};
    color: ${STYLE_COLOR.BLACK01};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 24px;
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Text: styled.p`
    ${STYLE_KEEP_WORD};
    margin-top: 24px;
    ${FONT_16};
    color: ${STYLE_COLOR.BLACK02};
    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      margin-top: 12px;
    }
  `,
}

export default Grid2Gallery
