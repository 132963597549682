import AutoLinkText from '@/components/AutoLinkText'
import DisplayConfigWrapper from '@/components/DisplayConfigWrapper'
import TransitionHandler from '@/components/TransitionHandler'
import elementItemHelper from '@/helpers/elementItemHelper'
import { STYLE_ARROW_BUTTON_WHITE } from '@/styles/buttons'
import {
  FONT_16,
  FONT_16_BOLD,
  FONT_22_EXTRA_BOLD,
  FONT_30_EXTRA_BOLD,
} from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import {
  ContentButtonItem,
  ContentImageItem,
  ContentRow,
  ContentTextItem,
} from '@/types/Content'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import Grid from '../Grid'

interface Grid2FullReportProps {
  item: ContentRow
}

const Partial = {
  Heading({
    title,
    style,
  }: {
    title?: ContentTextItem[]
    style?: CSSProperties
  }) {
    if (!title) return null
    return (
      <>
        {title.map(({ show, value, as }, index) => (
          <TransitionHandler key={`Grid2FullReportTab-heading-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Title as={as} style={style}>
                {value}
              </Styled.Title>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Image({ image }: { image?: ContentImageItem[] }) {
    if (!image) return null
    return (
      <>
        {image.map(({ show, src, alt }, index) => (
          <TransitionHandler key={`Grid2FullReportTab-image-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Image src={src} alt={alt || ''} />
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Text({ text }: { text?: ContentTextItem[] }) {
    if (!text) return null
    return (
      <>
        {text.map(({ show, value }, index) => (
          <TransitionHandler key={`Grid2FullReportTab-text-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Text>{value}</Styled.Text>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
  Button({ button }: { button?: ContentButtonItem[] }) {
    if (!button) return null
    return (
      <>
        {button.map(({ show, label, href }, index) => (
          <TransitionHandler key={`Grid2FullReportTab-image-${index}`}>
            <DisplayConfigWrapper show={show}>
              <Styled.Link href={href}>{label}</Styled.Link>
            </DisplayConfigWrapper>
          </TransitionHandler>
        ))}
      </>
    )
  },
}

function Grid2FullReportTab({ item }: Grid2FullReportProps) {
  const { columns, type, htmlId, isScrollToHash } = item
  const [{ items: leftColumnItems }, { items: rightColumnItems }] = columns ?? [
    {},
    {},
  ]

  const { title, image } = elementItemHelper(leftColumnItems)
  const {
    title: rightTitle,
    text,
    button,
  } = elementItemHelper(rightColumnItems)

  return (
    <Styled.Wrap id={htmlId} data-is-scroll-to-hash={isScrollToHash}>
      <Grid gridType={type}>
        <div>
          <Partial.Heading title={title} style={{ paddingTop: 77 }} />
          <Partial.Image image={image} />
        </div>
        <div>
          <Partial.Heading title={rightTitle} />
          <Partial.Text text={text} />
          <Partial.Button button={button} />
        </div>
      </Grid>
    </Styled.Wrap>
  )
}

const Styled = {
  Wrap: styled.div`
    background-color: ${STYLE_COLOR.DARK_BG};
    color: ${STYLE_COLOR.WHITE01};
  `,
  Title: styled.p<{ level?: number }>`
    margin: 0;
    ${FONT_30_EXTRA_BOLD};
    white-space: pre-wrap;

    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 60px;
      ${FONT_22_EXTRA_BOLD};
    }
  `,
  Image: styled.img`
    width: 100%;
    padding: 80px 70px;
    box-sizing: border-box;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding: 60px 43px;
    }
  `,
  Text: styled.p`
    padding-top: 24px;
    ${FONT_16};

    white-space: pre-wrap;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
    }
  `,
  Link: styled(AutoLinkText)`
    ${FONT_16_BOLD};
    ${STYLE_ARROW_BUTTON_WHITE};
    margin-top: 30px;
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      padding-top: 12px;
      padding-bottom: 64px;
    }
  `,
}

export default Grid2FullReportTab
